@import url("swiper/swiper-bundle.min.css");


:root {
  --gray-light-1: #FAFAFA;
  --gray-light-2: #F0F0F0;
  --gray: #8C8C8C;
  --gradient: linear-gradient(275deg, #13AD37 11.87%, #5DAD13 86.48%);
  --primary: #13AD37;
  --blue: #1A6AFD;
}
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: Inter;
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: Inter;
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: Inter;
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: Inter;
  src: url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  font-family: Inter, Helvetica, serif;
}
body {
  margin: 0;
  min-width: 320px;
  font-family: Inter, Helvetica, serif;
  font-weight: 400;
}

.wrapper {
  max-width: 1268px;
  width: 100%;
  margin: 0 auto;
  min-width: 360px;
}


.screen-height {
  min-height: 100vh;
}
.map-desc {
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  background: #000000;
  padding: 8px 12px;
  letter-spacing: 0.03em;
  min-width: 340px;
  color: #FFFFFF;
  left: -150px;
  bottom: 45px;
  border-radius: 8px;
}



.map-marker {
  position: relative;
  top: -54px;
  left: -28px;
  width: auto;
  height: auto;
  transition: all .3s;
}

.map-marker svg{
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  height: auto;
}

.close-click {
  position: relative;
  top: -24px;
  z-index: 1000;
  left: -12px;
  cursor: pointer;
  width: auto;
  height: auto;
}

.close-click svg{
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  height: auto;
}

.mark {
  cursor: pointer;

  transition: fill 0.3s ease-in-out, r 0.3s ease-in-out;
}

.modal-content {
  border: none !important;
  padding: 30px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.hide {
  display: none !important;
}
.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.popup-content {
  z-index: 6 !important;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.path {
  fill: #262626;
  transition: fill 0.3s ease-in-out;
}

.group:hover .path {
  fill: #1f1f1f;
}

.path.active {
  fill: #13ad37 !important;
}




.repr__tooltip rect,
.repr__tooltip text {
  transition: fill 0.3s ease-in-out;
}

a {
  text-decoration: none;
}

.repr__tooltip:hover path,
.repr__tooltip:hover rect {
  fill: #fff;
}

.repr__tooltip:hover text {
  fill: #13ad37;
}

.close-icon {
  color: #8e9aa9;
  transition: color 0.3s ease-in-out;
}

.close-icon:hover {
  color: #1f222d;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}


@media (max-width: 4000px){
  .wrapper {
    max-width: 2455px;
  }
}

@media (max-width: 2559px){
  .wrapper {
    max-width: 1814px;
  }
}
@media (max-width: 1919px){
  .wrapper {
    max-width: 1512px;
  }
}
@media (max-width: 1599px){
  .wrapper {
    max-width: 1268px;
  }
}
@media (max-width: 1365px){
  .wrapper {
    max-width: 1183px;
  }
}
@media (max-width: 1279px){
  .wrapper {
    max-width: 911px;
  }
}
@media (max-width: 990px){
  .wrapper {
    max-width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media (max-width: 375px){
  .wrapper {
    padding: 0 16px;
  }
}

@media (max-width: 500px) {
  .map-desc {
    min-width: 270px;
    font-size: 12px;
    color: #FFFFFF;
    left: -80px;
    bottom: 45px;
    border-radius: 8px;
  }
}

.tooltip-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  fill: #ffffff;
}

.dist-region {
  transition: fill 0.3s ease-in-out;
}

.dist-region:hover,
.dist-region.active {
  fill: #119d32;
}

.dist-modal-content {
  z-index: 9 !important;
}

.dist-mark {
  cursor: pointer;
  transition: r 0.3s ease-in-out;
}

#tooltip-map {
  position: fixed;
  z-index: 9;
  background: #ffffff;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 8px;
  box-sizing: border-box;
  letter-spacing: 0.03em;
  color: #13ad37;
  border-radius: 19px;
  pointer-events: none;
  user-select: none;
  visibility: hidden;
}

#tooltip-map:after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid #fff;
}
.closemap {
  position: absolute;
  top: -10px;
  cursor: pointer;
  right: -10px;
  z-index: 1000;
}
@media (max-width: 1050px) {
  .tooltip-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

.overlay {
  position: fixed;
  z-index: 12;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .map {
    font-size: 10px;
  }
}

.popover {
  display: block;
}
.popover .close {
  position: absolute;
  right: 5px;
  top: 1px;
}
.btn {
  margin-top: 10px;
}

.distributor-desc {
  box-sizing: border-box;
  background: #FFFFFF;
  padding: 14px;
  height: auto;
  min-width: 100%;
  color: #8E9AA9;
}


.distributor-title {
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  margin-bottom: 4px;
}

.distributor-address {
  font-size: 14px;
  line-height: 150%;
  max-width: 317px;
}

.contact-list {
  list-style-type: none;
  margin: 0;

  font-size: 12px;
}

.contact-list li{
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-list p{
  margin: 0;
}
.list-style {
  width: 8px;
  height: 8px;
  background: #13AD37;
  border-radius: 50%;
}
.distributor-contacts {
  margin: 16px 0 6px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: #1F222D;
}


.ymaps-2-1-79-balloon__content {
  padding: 10px !important;
}

.ymaps-2-1-79-balloon__layout {
  border-radius: 24px;
}

.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__close-button {
  margin-top: 10px;
  margin-right: 12px;
}

.big-marker {
  top: -75px;
  left: -40px;
  transform: scale(1.4);
}

#greenCluster {
  width: 46px;
  height: 46px;
  background: #13AD37;
}

.clusterIcon {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  width: 34px;
  position: absolute;
  left: -17px;
  top: -20px;
  text-align: center;
  padding-top: 9px;
  border-radius: 50%;
  height: 25px;
  background: #FFFFFF;
  font-weight: 700;
  border: 2px solid #13AD37;
  color: #000000;
}